.BannerTwo {
  height: 45rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 5rem 5rem 5rem;
  position: relative;
  overflow: hidden;
  background: none;
  background-color: transparent;
  margin-bottom: 8.5rem;
}
.BannerTwo:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(92.53deg, #055aa5 5.99%, rgba(198, 198, 198, 0) 91.74%);
  z-index: 0;
}
.BannerTwo--tag, .BannerTwo--title {
  position: relative;
  z-index: 1;
}
.BannerTwo--tag {
  color: #fff;
  font-family: "Mulish";
  font-weight: 600;
  font-size: 2.4rem;
  border-radius: 50rem;
  border: solid 2px #fff;
  padding: 0.5rem 2rem;
  margin-bottom: 2rem;
  width: fit-content;
  text-decoration: none;
}
.BannerTwo--title {
  font-weight: 600;
  font-size: 4rem;
  line-height: 5.5rem;
  color: #fff;
  width: 80%;
  font-family: "TripletaBold";
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .BannerTwo {
    padding: 0 2rem 2rem 2rem;
    margin-bottom: 4rem;
    height: 35rem;
  }
  .BannerTwo--title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .BannerTwo--tag {
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem;
  }
}