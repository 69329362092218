.FAQ {
  padding-top: 4rem;
  padding: 0 1.6rem;
}
.FAQ--title {
  font-family: "Mulish", sans-serif;
  font-size: 6rem;
  font-weight: 900;
  line-height: 1.1;
  border-bottom: solid 0.1rem #010205;
  padding-bottom: 3rem;
  margin-bottom: 0;
}
.FAQ a {
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #b2b2b2;
  text-transform: uppercase;
  text-decoration: underline;
}
.FAQ a:hover {
  text-decoration: none;
}
.FAQ--btn {
  border-radius: 100px;
  background-color: #010205;
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 3.6rem;
  padding: 0.8rem 3.2rem;
  display: inline-flex;
  width: fit-content;
}
.FAQ--places {
  display: flex;
  gap: 2rem;
}
.FAQ--place {
  background-color: #f2f2f2;
  border-radius: 100px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
}
.FAQ--accordion {
  margin-bottom: 10rem;
}
.FAQ--accordion .card {
  border: 0;
}
.FAQ--accordion .card:has(.show) .card-header {
  background: url("../../../../../images/minus.svg") no-repeat center right 16px/18px;
}
.FAQ--accordion .card:has(.show) .card-header h5 button {
  font-weight: 600;
  color: #cd202f;
}
.FAQ--accordion .card-header {
  padding: 0;
  background-color: white;
  border: 0;
  border-bottom: solid 0.1rem #010205;
  padding: 12px 0;
  background: url("../../../../../images/plus-black.svg") no-repeat center right 16px/18px;
}
.FAQ--accordion .card-header h5 button {
  font-family: "Work Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #010205;
  text-decoration: none;
  text-align: left;
  padding-right: 6rem;
  width: 100%;
}
.FAQ--accordion .card-header h5 button:active {
  color: #010205;
}
.FAQ--accordion .card-body {
  font-family: "Work Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #010205;
  text-decoration: none;
}
.FAQ--accordion .collapse {
  overflow: hidden;
  height: 0;
  transition: height 0.35s ease-in-out;
}
.FAQ--accordion .collapse.show {
  height: auto;
}

@media (max-width: 768px) {
  .FAQ {
    padding: 0 1.6rem;
  }
  .FAQ--title {
    color: #010205;
    font-size: 3.5rem;
    line-height: 4rem;
    margin-bottom: 0;
  }
  .FAQ--accordion {
    margin-bottom: 6rem;
  }
  .FAQ--accordion .card-header,
  .FAQ--accordion .card:has(.show) .card-header {
    background-position: center right;
  }
  .FAQ--accordion .card-header h5 button,
  .FAQ--accordion .card:has(.show) .card-header h5 button {
    padding: 0;
  }
  .FAQ--subtitle {
    text-align: right;
    margin-top: 2rem;
  }
}