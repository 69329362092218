.BannerThree {
  margin-bottom: 9rem;
  display: flex;
}
.BannerThree--banner {
  border-radius: 2rem;
  width: 32rem;
  margin-right: 10px;
  min-width: 23.4rem;
}
.BannerThree--flex {
  display: flex;
}
.BannerThree--bannertwo {
  border-radius: 2rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.BannerThree--tag {
  color: #fff;
  font-family: "Mulish";
  font-weight: 600;
  font-size: 2.4rem;
  border-radius: 50rem;
  border: solid 2px #fff;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
  width: fit-content;
  text-decoration: none;
}
.BannerThree--title {
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.5rem;
  color: #fff;
  width: 70%;
  font-family: "TripletaBold";
}

@media (max-width: 768px) {
  .BannerThree {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }
  .BannerThree--banner {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    height: 20rem;
    border-radius: 2rem 2rem 0 0;
  }
  .BannerThree--flex {
    flex-direction: column;
    align-items: center;
  }
  .BannerThree--bannertwo {
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
  }
  .BannerThree--title {
    width: 100%;
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .BannerThree--tag {
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem;
  }
}