.Rolling {
  margin-top: 8rem;
  margin-bottom: 11rem;
}
.Rolling--item {
  display: flex;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}
.Rolling--item--active {
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.Rolling--item--active h4 {
  color: #cd202f;
  font-weight: 900;
}
.Rolling--item--active .Rolling--block {
  border-left: solid 2px #cd202f;
}
.Rolling--item--active .Rolling--index {
  color: #cd202f;
}
.Rolling--title {
  margin: 2rem 0;
  font-family: "Mulish";
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.4rem;
}
.Rolling--index {
  margin-right: 5rem;
  font-family: "Mulish";
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 34px;
}
.Rolling--block {
  border-left: solid 2px #eceef3;
  padding: 2rem 2rem 5rem 5rem;
}
.Rolling--img {
  width: 3.3rem;
  height: 3.3rem;
  object-fit: contain;
}
.Rolling--info b,
.Rolling--info strong {
  color: #000;
}

@media (max-width: 768px) {
  .Rolling {
    margin: 4rem 1rem 6rem 1rem;
  }
  .Rolling--item--active h4 {
    font-size: 2.4rem;
  }
  .Rolling--title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .Rolling--index {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .Rolling--block {
    padding: 3rem;
  }
  .Rolling--info {
    font-size: 1.6rem;
  }
}