.FarmacovigilanciaLandingPage--form {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.FarmacovigilanciaLandingPage--form-form {
  padding: 0;
}
.FarmacovigilanciaLandingPage--form-form .form-group {
  margin-top: 0;
}
.FarmacovigilanciaLandingPage--form-form .form-group .form-control {
  height: 2rem;
  background-color: transparent;
  border: 0;
  border-bottom: solid 1px #4a4a4a;
  border-radius: 0;
  padding: 0;
  padding-bottom: 0.6rem;
}
.FarmacovigilanciaLandingPage--form-form .form-group .form-control::placeholder {
  color: #4a4a4a;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.FarmacovigilanciaLandingPage--form-form .form-group .form-control.is-invalid {
  border-color: #cd202f;
  margin-bottom: 0;
}
.FarmacovigilanciaLandingPage--form-form .form-group:has(.is-invalid) .invalid-feedback {
  margin-bottom: 1rem;
}
.FarmacovigilanciaLandingPage--form-form .form-group:has(.is-invalid) .form-control::placeholder {
  color: #cd202f;
}
.FarmacovigilanciaLandingPage--form-form .leti-btn {
  background-color: #055aa5;
  color: #fff;
  padding: 0.6rem;
  width: 100%;
}
.FarmacovigilanciaLandingPage--form-message {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

@media (max-width: 768px) {
  .FarmacovigilanciaLandingPage--form {
    padding: 2rem;
  }
  .FarmacovigilanciaLandingPage--form-form .form-group .form-control {
    font-size: 1.2rem;
  }
}