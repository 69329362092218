.EditBanner .is-message {
  color: #055aa5;
  font-size: 1.8rem;
}
.EditBanner .form-group {
  width: 100%;
}
.EditBanner .btn-delete {
  color: #fff;
  border: solid 0.1rem #cd202f;
  font-weight: 400;
  text-decoration: none;
  font-size: 2rem;
  display: inline-flex;
  background-color: #cd202f;
  justify-content: center;
  align-items: center;
  padding: 1.4rem 3.2rem;
  transition: all 200ms ease-in-out;
  border-radius: 0;
}
.EditBanner .btn-delete:hover {
  background-color: #fff;
  color: #cd202f;
}