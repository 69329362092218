.InfoTwo {
  margin-bottom: 8.5rem;
}
.InfoTwo--title {
  font-family: "Mulish";
  font-weight: 900;
  font-size: 6rem;
  line-height: 6.5rem;
}
.InfoTwo--description p {
  margin-bottom: 4rem;
}
.InfoTwo--description p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .InfoTwo {
    margin-bottom: 4rem;
  }
  .InfoTwo--title {
    font-size: 3.5rem;
    line-height: 4rem;
    margin-bottom: 2rem;
  }
  .InfoTwo--description p {
    font-size: 2rem;
    line-height: 3rem;
  }
}