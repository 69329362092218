.OurCommitment h1 {
  font-family: "mulish";
  font-weight: 800;
  font-size: 6rem;
  line-height: 7rem;
  margin-bottom: 4rem;
}
.OurCommitment p {
  font-family: "mulish";
  font-weight: 300;
  font-size: 3rem;
  line-height: 3.5rem;
  margin-bottom: 4rem;
}

@media (max-width: 768px) {
  .OurCommitment h1 {
    font-size: 3.5rem;
    line-height: 4rem;
    margin-bottom: 2rem;
  }
  .OurCommitment p {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
}