.FarmacovigilanciaLandingPage {
  background: url(../../../../images/farmacovig-bg.svg) no-repeat center right -24rem/80rem;
}
.FarmacovigilanciaLandingPage--banner {
  border-radius: 2rem;
  display: flex;
  height: 58rem;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 5rem 10rem 5rem;
  margin-bottom: 8rem;
}
.FarmacovigilanciaLandingPage--banner h1 {
  font-weight: 600;
  font-size: 5rem;
  line-height: 5.5rem;
  color: #4a4a4a;
  width: 50%;
  font-family: "TripletaBold";
  margin-bottom: 2rem;
}
.FarmacovigilanciaLandingPage--banner h2 {
  font-family: "Tripleta";
  font-weight: 100;
  font-size: 3rem;
  line-height: 3.5rem;
  width: 50%;
  color: #4a4a4a;
}
.FarmacovigilanciaLandingPage--form {
  border-radius: 2rem;
  padding: 3rem;
  background-color: #ecedf0;
  height: 58rem;
  position: fixed;
  top: 140px;
  align-self: flex-start;
}
.FarmacovigilanciaLandingPage--form h1 {
  color: #4a4a4a;
  font-family: "Mulish";
  font-weight: 300;
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: center;
  margin-bottom: 2rem;
}
.FarmacovigilanciaLandingPage--form h1 p {
  font-size: 3rem;
  line-height: 3.5rem;
}
.FarmacovigilanciaLandingPage--form h1 b,
.FarmacovigilanciaLandingPage--form h1 strong {
  font-weight: 900;
}
.FarmacovigilanciaLandingPage--form p {
  color: #4a4a4a;
  font-family: "Mulish";
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .FarmacovigilanciaLandingPage--banner {
    height: 45rem;
    padding: 0 2rem 5rem 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  .FarmacovigilanciaLandingPage--banner h1 {
    font-size: 3.5rem;
    line-height: 4rem;
    width: 100%;
    margin-bottom: 1rem;
  }
  .FarmacovigilanciaLandingPage--banner h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    width: 100%;
  }
  .FarmacovigilanciaLandingPage--form {
    padding: 2rem;
    border-radius: 0;
    position: static !important;
    margin-bottom: 0 !important;
    width: 100% !important;
  }
  .FarmacovigilanciaLandingPage--form h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 1rem;
  }
  .FarmacovigilanciaLandingPage--form p {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}